import IEnum from '../interfaces/IEnum';

export const ProcessPhases: IEnum = {
    translations: {
        0: 'lang.shared.inscriptionsPhase',
        1: 'lang.shared.evaluationPhase',
        2: 'lang.shared.rankingPhase',
        3: 'lang.shared.finishPhase'
    },
    enum: {
        INSCRIPTIONS: 0, // Fase 1 - inscripciones
        EVALUATION: 1, // Fase 2 - calificaciones
        RANKING: 2, // Fase 3 - nombramiento / ocupación
        FINISH: 3, // Fase 4 - final
    }
};
