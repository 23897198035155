import { BrowserTypes } from "./enum/BrowserTypes";

export class Browser {
    private _validBrowser: boolean;
    private _invalidBrowser: boolean;
    private _notRecommendBrowser: boolean;

    constructor() {
        this._validBrowser = false;
        this._invalidBrowser = false;
        this._notRecommendBrowser = false;
        this.detectUserAgent();
    }

    public get validBrowser() {
        return this._validBrowser;
    }
    
    public set validBrowser(validBrowser: boolean){
        this._validBrowser = validBrowser;
    }

    public get invalidBrowser() {
        return this._invalidBrowser;
    }
    
    public set invalidBrowser(invalidBrowser: boolean){
        this._invalidBrowser = invalidBrowser;
    }

    public get notRecommendBrowser() {
        return this._notRecommendBrowser;
    }
    
    public set notRecommendBrowser(notRecommendBrowser: boolean){
        this._notRecommendBrowser = notRecommendBrowser;
    }

    public detectUserAgent() {
        var agent = window.navigator.userAgent;
  
        if (agent.indexOf(BrowserTypes.match[BrowserTypes.enum.EDGE]) != -1) {
            // EDGE
            this.validBrowser = true;
        } else if (agent.indexOf(BrowserTypes.match[BrowserTypes.enum.CHROME]) != -1 && agent.indexOf(BrowserTypes.match[BrowserTypes.enum.OPERA]) == -1 && agent.indexOf(BrowserTypes.match[BrowserTypes.enum.EDGE]) == -1) {
            // CHROME
            this.validBrowser = true;
        } else if (agent.indexOf(BrowserTypes.match[BrowserTypes.enum.MOZILLA]) != -1) {
            // MOZILLA
            this.validBrowser = true;
        } else if (agent.indexOf(BrowserTypes.match[BrowserTypes.enum.SAFARI]) != -1 && agent.indexOf(BrowserTypes.match[BrowserTypes.enum.CHROME]) == -1) {
            // SAFARI
            this.notRecommendBrowser = true;
        } else if (agent.indexOf(BrowserTypes.match[BrowserTypes.enum.OPERA]) != -1) {
            // OPERA
            this.notRecommendBrowser = true;
        } else if (agent.indexOf(BrowserTypes.match[BrowserTypes.enum.IE]) != -1 || agent.indexOf(BrowserTypes.match[BrowserTypes.enum.IE_NEW]) != -1) {
            // IE
            this.invalidBrowser = true;
        } else {
            // OTHERS
            this.notRecommendBrowser = true;
        }
    }
}
