import { FilterElement } from './FilterElement';
import { FilterFactory } from '../../../application/services/FilterFactory';

const filterFactory = new FilterFactory();

export class Filter {
    private _filterElements: FilterElement[];
    private _all: any[];

    constructor() {
        this._all = [];
        this._filterElements = [];
    }
    public get filterElements() {
        return this._filterElements;
    }
    public get all() {
        return this._all;
    }

    public setData(data: any[]) {
        this._all = data;
    }
    public setFilterElements(data: any[]) {
        this._filterElements = data.map(filter => filterFactory.createFilterElement(filter));
    }

    public resetFilterElements() {
        this._filterElements.forEach(filter => filter.reset());
    }
    public filteredData(currentLanguage: string): any[] {
        if (!this._all) { return []; }
        return this._all.filter(element => {
            return !this._filterElements.some(filter => !filter.showElement(element, currentLanguage));
        });
    }
}
