
import { getHeaderConfiguration, getClientAvailableLanguajes, getFeatureFlag } from "../../infrastructure/apiServices/masterData.service";
import { Constants } from "../../domain/enum/Constants";
import Cookies from "js-cookie";

export const loadCommonData = function(vm, app, locale){
  getClientAvailableLanguajes().then((response: any) => {
    vm.availableLanguajes.value = response.data;
    locale.value = app.getLanguaje(response.data.defaultLanguage);
    const langs = [response.data.defaultLanguage, response.data.secondLanguage].filter(Boolean);
    app.headerLangs = langs;
    vm.getAllData();
  }).catch(error => {
    locale.value = Constants.language.ES_ES;
    vm.getAllData();
  });

  Promise.all([getFeatureFlag(), getHeaderConfiguration()]).then((response: any) => {
    // FEATURE FLAGS
    response[0].data.map(element => app.featureFlags[element] = true);
    app.isLanguajeLoad = true;

    // COOKIES
    vm.cookie.hasOptanonAlertBoxClosed = Cookies.get('OptanonAlertBoxClosed') !== undefined;
    if (vm.cookie.hasOptanonAlertBoxClosed) {
      vm.cookie.hasAcceptedAnalysisCookies = Cookies.get('AcceptedAnalysisCookies') !== undefined;
    }
    vm.cookie.setConfigCookies();

    // HEADER CONFIG
    let headerConfigResponse = response[1].data;
    let headerConfigProxy;
   
    if(vm.isCompositionApi) {
      vm.headerConfig.value = headerConfigResponse;
      headerConfigProxy = vm.headerConfig.value;
    } else {
      vm.headerConfig = headerConfigResponse;
      headerConfigProxy = vm.headerConfig;
    }
    
    const link: any = document.querySelector("link[rel~='icon']");
    let title = document.getElementById('title');
    if (headerConfigProxy.favicon !== null && headerConfigProxy.favicon.image !== null) {
      link.href = 'data:image;base64,' + headerConfigProxy.favicon.image;
      title.innerText= headerConfigProxy.favicon.title;
    } else {
      link.href = './convoca_logo.png';
      title.innerText= 'Oferta de empleo público';
    }
    if ((vm.app.state === 'replacementList' || vm.app.state === 'replacementListDetail') && !headerConfigProxy.replacementListsVisible ||
        (vm.app.state === 'paymentRefund' && !vm.app.featureFlags.paymentRefund)) {
      location.href = app.origin + '/index.html';
    }

    const backgroundColor = headerConfigProxy.backgroundColor || '#1976d2';
    const textColor = headerConfigProxy.textColor || '#ffffff';

    const elements = document.querySelectorAll('.applicant-nav, .applicant-footer');

    elements.forEach(element => {
      let nodeItemAsHtml: HTMLElement = element as HTMLElement;
      nodeItemAsHtml.style.setProperty('--bg', backgroundColor);
      nodeItemAsHtml.style.setProperty('--color', textColor);
    });

    const border = document.querySelector('.applicant-nav-desktop__item.active');
    if(border) {
      (border as HTMLElement).style.setProperty('--border', textColor);
    }

  }).catch((error) => {
    console.log(error);
    app.isLanguajeLoad = true;
  })
}
