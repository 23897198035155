import { FilterElement } from './FilterElement';
import { MultiLanguage } from '../../MultiLanguage';

export class InputFilterElement extends FilterElement {

    public get filterString() {
        return this._filterString;
    }
    public set filterString(filterString: string) {
        this._filterString = filterString;
    }
    public get icon() {
        return this._icon;
    }

    private _filterString: string;
    private _params: string[];
    private _icon: string;

    constructor(data: any) {
        super(data);
        this._params = [].concat(data.param);
        this._filterString = data.filterString || '';
        this._icon = data.icon;
    }
    public reset() {
        this._filterString = '';
    }
    public get hasFilterValue() {
        return this._filterString !== '';
    }

    public showElement(element: any, currentLanguage: string): boolean {
        const filterString = this.normalizeString(this._filterString);
        return this._params.filter(param => this.normalizeString(MultiLanguage.getTranslationToFilter(element[param], currentLanguage)).includes(filterString)).length > 0;
    }

    private normalizeString(string: string): string {
        return string ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : string;
    }
}
