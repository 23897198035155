export class Entity{
    private _id: string;
    private _name: string;

    constructor(id?: string, name?: string){
        this._id = id || "";
        this._name = name || "";
    }

    public set name(name: string){
        this._name = name;
    }

    public get name(){
        return this._name;
    }

    public set id(id: string){
        this._id = id;
    }

    public get id(){
        return this._id;
    }
}

