export class Vacancies {
    private _free: number | null;
    private _internal: number | null;
    private _internalDisability: number | null;
    private _freeDisability: number | null;
    private _mobility: number | null;
    private _total: number | null;
    private _extraFree: number | null;
    private _extraInternal: number | null;
    private _extraInternalDisability: number | null;
    private _extraFreeDisability: number | null;
    private _extraMobility: number | null;
    private _totalExtras: number | null;

    constructor(data: any) {
        this._free = data.free;
        this._internal = data.internal;
        this._internalDisability = data.internalDisability;
        this._freeDisability = data.freeDisability;
        this._mobility = data.mobility;
        this._total = data.total;
        this._extraFree = data.extraFree;
        this._extraInternal = data.extraInternal;
        this._extraInternalDisability = data.extraInternalDisability;
        this._extraFreeDisability = data.extraFreeDisability;
        this._extraMobility = data.extraMobility;
        this._totalExtras = data.totalExtras;
    }

    public get free(): number | null {
        return this._free;
    }

    public get internal(): number | null {
        return this._internal;
    }
    
    public get internalDisability(): number | null {
        return this._internalDisability;
    }

    public get freeDisability(): number | null {
        return this._freeDisability;
    }

    public get mobility(): number | null {
        return this._mobility;
    }

    public get total(): number | null {
        return this._total;
    }

    public get extraFree() {
        return this._extraFree;
    }

    public get extraInternal() {
        return this._extraInternal;
    }

    public get extraInternalDisability() {
        return this._extraInternalDisability;
    }

    public get extraFreeDisability() {
        return this._extraFreeDisability;
    }

    public get extraMobility() {
        return this._extraMobility;
    }

    public get totalExtras() {
        return this._totalExtras;
    }

    public getTotalVacanciesAndExtra() {
        return this.total + this.totalExtras;
    }

}