import * as OrderSelect from './OrderSelect';

export function setMunicipalities(municipalities, municipalityInscription) {
    var municipalitiesArray = [];
    municipalities.forEach(municipality => {
      if (!municipality.isObsolete || municipalityInscription === municipality.municipalityCode) {
        municipalitiesArray.push({ name: municipality.municipalityName, id: municipality.municipalityCode, disabled: municipality.isObsolete });
      }
    });
    return OrderSelect.prepareDataArrayObject(municipalitiesArray);
}
