import Cookies from "js-cookie";
import moment from "../node_modules/moment/moment";

export class Cookie {
    private _hasOptanonAlertBoxClosed: boolean;
    private _hasAcceptedAnalysisCookies: boolean;
    private _mandatoryCookies: boolean;
    private _analysisCookies: boolean;

    constructor(){
        this._hasOptanonAlertBoxClosed = false;
        this._hasAcceptedAnalysisCookies = false;
        this._mandatoryCookies = false;
        this._analysisCookies = false;
    }

    public set hasOptanonAlertBoxClosed(hasOptanonAlertBoxClosed: boolean){
        this._hasOptanonAlertBoxClosed = hasOptanonAlertBoxClosed;
    }

    public get hasOptanonAlertBoxClosed(){
        return this._hasOptanonAlertBoxClosed;
    }

    public set hasAcceptedAnalysisCookies(hasAcceptedAnalysisCookies: boolean){
        this._hasAcceptedAnalysisCookies = hasAcceptedAnalysisCookies;
    }

    public get hasAcceptedAnalysisCookies(){
        return this._hasAcceptedAnalysisCookies;
    }

    public set mandatoryCookies(mandatoryCookies: boolean){
        this._mandatoryCookies = mandatoryCookies;
    }

    public get mandatoryCookies(){
        return this._mandatoryCookies;
    }

    public set analysisCookies(analysisCookies: boolean){
        this._analysisCookies = analysisCookies;
    }

    public get analysisCookies(){
        return this._analysisCookies;
    }

    public acceptAllCookies() {
        this.mandatoryCookies = true;
        this.analysisCookies = true;
        this.setConfigCookies();
    }

    public declineAllCookies() {
        this.mandatoryCookies = true;
        this.analysisCookies = false;
        this.setConfigCookies();
    }

    public savePersonalizateCookies() {
        Cookies.remove('AcceptedAnalysisCookies');
        this.mandatoryCookies = true;
        this.setConfigCookies();
    }

    public setConfigCookies() {
        if (this.mandatoryCookies){
            Cookies.set('OptanonAlertBoxClosed', moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), { expires: 90 });
            this.hasOptanonAlertBoxClosed = true;
        }

        if (this.hasAcceptedAnalysisCookies || this.analysisCookies) {
            Cookies.set('AcceptedAnalysisCookies', moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), { expires: 90 });
            const hotjarScript = document.createElement('script');
            hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3262072,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;
            document.head.appendChild(hotjarScript);
        }
    }
}
