export function prepareDataArrayObject(options, t = null) {
    if (!Array.isArray(options) || typeof options[0] !== 'object') {
        throw new Error("invalid element, should be Array and typeof distinct object");
    }
    var arrayList = options.map(element => ({ name: t ? t(element.name) : element.name, id: element.id, disabled: element.disabled }));
    return orderArrayList(arrayList);
}

export function prepareDataArrayNotObject(options) {
    if (!Array.isArray(options) || typeof options[0] === 'object') {
        throw new Error("invalid element, should be Array and typeof object");
    }
    return options.map(element => ({ name: element }));
}

export function prepareDataNotArray(options, t = null) {
    if (Array.isArray(options)) {
        throw new Error("invalid element, should not be Array");
    }
    var arrayList = addElementsToArray(options, t);
    return orderArrayList(arrayList);
}

function addElementsToArray(elements, t = null) {
    var arrayListAdded = [];
    for (const element in elements) {
        if (elements[element]) {
            arrayListAdded.push({ name: t ? t(elements[element]) : elements[element], id: element });
        }
    }
    return arrayListAdded;
}

function orderArrayList(arrayList) {
    return arrayList.sort((a, b) => a.name.localeCompare(b.name));
}
