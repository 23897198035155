import IEnum from '../interfaces/IEnum';

export const ProcessType: IEnum = {
    translations: {
        0: 'lang.shared.callCapitalize',
        1: 'lang.shared.bagCapitalize',
        2: 'lang.shared.postProvision'
    },
    enum: {
        CALL: 0,
        EMPLOYMENT_BAG: 1,
        POSTPROVISION: 2,
        BOEP: 3
    },
    urlString: {
        0: 'calls',
        1: 'bags',
        2: 'postProvisions'
    },
    title: {
        0: 'lang.shared.calls',
        1: 'lang.shared.bagCapitalize',
        2: 'lang.shared.postProvision'
    },
    titleSingle: {
        0: 'lang.shared.call',
        1: 'lang.shared.bag',
        2: 'lang.shared.postProvisionLowerCase'
    },
    image: {
        0: 'applicant-hero-image--call',
        1: 'applicant-hero-image--employment',
        2: 'applicant-hero-image--post-provision'
    }
};
