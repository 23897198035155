import IEnum from '../interfaces/IEnum';

export const Languages: IEnum = {
    translations: {
        'es-ES': 'lang.shared.castellano',
        'ca-ES': 'lang.shared.catalan',
        'gl-ES': 'lang.shared.gallego',
        'eu-ES': 'lang.shared.euskera',
        'pt-PT': 'lang.shared.valenciano'
    },
    flags: {
        'es-ES': 'bandera_espana.png',
        'ca-ES': 'bandera_catalunya.png',
        'gl-ES': 'bandera_galicia.png',
        'eu-ES': 'bandera_pvasco.png',
        'pt-PT': 'bandera_valencia.png'
    }
};
