import IEnum from '../interfaces/IEnum';

export const BrowserTypes: IEnum = {
    enum: {
        EDGE: 0,
        CHROME: 1,
        MOZILLA: 2,
        SAFARI: 3,
        OPERA: 4,
        IE: 5,
        IE_NEW: 6
    },
    match: {
        0: 'Edg',
        1: 'Chrome',
        2: 'Firefox',
        3: 'Safari',
        4: 'OPR',
        5: 'MSIE',
        6: 'Trident'
    }
};
