import axios from "axios";
import {masterDataCountries, masterDataStreetTypes, masterDataProvinces, masterDataAutonomousCommunity, masterDataHeaderConfiguration, masterDataClientInfo, masterDataNationalities, masterDataMunicipalities, masterDataLanguajes, masterDataFlags, processesConfigurationPayExemptionList, processesConfigurationAuthorizationList, processesConfigurationGroup, clientProviders } from "./services.config";

export let getMasterDataCountries = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataCountries()));
    })
}

export let getMasterDataStreetTypes = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataStreetTypes()));
    })
}

export let getMasterDataProvinces = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataProvinces()));
    })
}

export let getMasterDataMunicipalities = function (provinceId: number) {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataMunicipalities(provinceId)));
    })
}

export let getMasterDataAutonomousCommunity = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataAutonomousCommunity()));
    })
}


export let getHeaderConfiguration = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataHeaderConfiguration()));
    })
}

export let getClientInfo = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataClientInfo()));
    })
}

export let getMasterDataNationalities = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataNationalities()));
    })
}

export let getClientAvailableLanguajes = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataLanguajes()));
    })
}

export let getFeatureFlag = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(masterDataFlags()));
    })
}

export let getProcessesConfigurationPayExemptionList = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(processesConfigurationPayExemptionList()));
    })
}

export let getProcessesConfigurationAuthorizationList = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(processesConfigurationAuthorizationList()));
    })
}

export let getProcessesConfigurationGroup = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(processesConfigurationGroup()));
    })
}

export let getClientProviders = function() {
    return new Promise((resolve, reject) => {
        resolve(axios.get(clientProviders()));
    })
}
