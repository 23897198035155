import moment from "../node_modules/moment/moment";
import { PublicationTypes } from "./enum/PublicationTypes";
import { App } from "./App";
import { MultiLanguage } from "./MultiLanguage";
let app = new App();

export class Publication{
    private _id: string;
    private _title: MultiLanguage;
    private _description: MultiLanguage;
    private _extension: string;
    private _size : number;
    private _fileName: MultiLanguage;
    private _date: moment.Moment;
    private _type: number; 
    private _image: string;
    private _url: string
    private _publicationType: number;

    constructor(element: any){
        this._id = element.id;
        this._title = new MultiLanguage(element.processTitle);
        this._description = new MultiLanguage(element.description);
        this._extension = element.extension;
        this._size = element.size;
        this._fileName = new MultiLanguage(element.name);
        this._date = moment(element.publicationDate);
        this._type = element.type;
        this._image = PublicationTypes.image[element.type];
        this._url =  app.requestTo + element.url + "?origin=" + app.origin;
        this._publicationType = element.publicationType;
    }

    public get id(){
        return this._id;
    }

    public get title(){
        return this._title;
    }

    public get description(){
        return this._description;
    }

    public get extension(){
        return this._extension;
    }

    public get size(){
        return this._size;
    }

    public get fileName(){
        return this._fileName;
    }

    public get date() {
        return this._date;
    }

    public get type() {
        return this._type;
    }

    public get image() {
        return this._image;
    }

    public get url() {
        return this._url;
    }

    public get publicationType(){
        return this._publicationType;
    }

}

