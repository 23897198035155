import IEnum from '../interfaces/IEnum';

export const PublicationTypes: IEnum = {
    image: {
        0:'sidebar_otros-tipos.png',
        1:'sidebar_modelo-inscripcion.png',
        2:'sidebar_bases.png',
        3:'sidebar_listas-admitidos-excluidos.png',
        4:'sidebar_puntuaciones.png',
        5:'sidebar_tribunal.png',
        6:'sidebar_aprobados.png'
    },
    enum: {
        OTHER : 0,
        INSCRIPTION_MODEL: 1,
        BASES: 2,
        LISTS: 3,
        SCORES: 4,
        COURT: 5,
        APPROVED : 6
    }
};
