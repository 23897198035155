export class MultiLanguage {
    public constructor(data) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }

    public static getTranslationToFilter(multiLanguage: MultiLanguage | string, currentLanguage: string): string {
        if (typeof multiLanguage === 'string') {
            return multiLanguage;
        }
        if (!currentLanguage || !multiLanguage.hasKey(currentLanguage)) {
            return '';
        }

        return multiLanguage[currentLanguage];
    }

    public hasKey(key: string): boolean {
        return this.hasOwnProperty(key);
    }
}
