import axios from "axios";
import { urlDocuentsIdCall, urlProcessDetail, urlProcess, urlEntity, urlMainFileByIdProcess, urlEntities } from "./services.config";

//CALL
export let getProcessList = function (processType: number) {
    return new Promise((resolve, reject) => {
        resolve(axios.get(urlProcess(processType)));
    })
}

export let getProcessDetail = function (id: string, processType: number) {
    return new Promise((resolve, reject) => {
        resolve(axios.get(urlProcessDetail(id, processType)));
    })
}

export let getDocumentsByCallId = function (callId: string) {
    return new Promise((resolve, reject) => {
        resolve(axios.get(urlDocuentsIdCall(callId)));
    })
}

export let getEntityById = function (entityId: string) {
    return new Promise((resolve, reject) => {
        resolve(axios.get(urlEntity(entityId)));
    })
}

export let getEntities = function () {
    return new Promise((resolve, reject) => {
        resolve(axios.get(urlEntities()));
    })
}

//BAGS

//PROCESS
export let getMainFileById = function (callId: string) {
    return new Promise((resolve, reject) => {
        resolve(axios.get(urlMainFileByIdProcess(callId)));
    })
}