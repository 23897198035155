//LIBRERIES
import { computed, createApp, defineComponent, onMounted, Ref, ref, nextTick } from 'vue'
import { i18n } from '../lang/index';
import * as input from "../node_modules/saviafront/lib/js/compiled/md-input-module";
import * as select from "../node_modules/saviafront/lib/js/compiled/md-select-module.js";
import { useI18n } from 'vue-i18n';
import orderBy from "lodash/orderBy";
import deburr from "lodash/deburr";
import moment from "../node_modules/moment/moment";

//MODELS
import { App } from "../domain/App";
import { Process } from "../domain/entities/process/Process";
import { Entity } from "../domain/Entity";
import { Publication } from "../domain/Publication";
import { Cookie } from "../domain/Cookie";
import { ProcessStatus } from "../domain/enum/ProcessStatus";
import { ProcessPhases } from "../domain/enum/ProcessPhases";
import { ProcessType } from "../domain/enum/ProcessType";
import { OppositionType } from "../domain/enum/OppositionType";
import { Languages } from "../domain/enum/Languages";
import { Constants } from "../domain/enum/Constants";
import { Filter } from "../domain/entities/filter/Filter";
import { Filters } from '../domain/entities/filter/IndexFilters';
import { FilterTypes } from "../domain/enum/FilterTypes";
import { Representative } from "../domain/Representative";

//APISERVICES
import { getProcessList, getEntityById, getMainFileById } from "../infrastructure/apiServices/process.service";
import { getLastPublicationsList } from "../infrastructure/apiServices/publications.service";
import { loadCommonData } from "./services/loadCommonData";
import * as InputService from "./services/InputService";
import { getProcessesConfigurationGroup } from "../infrastructure/apiServices/masterData.service";
import * as MunicipalitiesService from "./services/MunicipalitiesService";
import { getMasterDataCountries, getMasterDataStreetTypes, getMasterDataProvinces, getMasterDataAutonomousCommunity, getMasterDataMunicipalities  } from "../infrastructure/apiServices/masterData.service";
import * as OrderSelect from "./services/OrderSelect";

const app = new App();

export const IndexComponent = defineComponent({
  setup() {
    // dependencies
    const { t, locale } = useI18n();
    const appRef = ref(app);

    // computed properties
    const pageTitle = computed(() => t(ProcessType.title[processTypeSelected]));
    const currentLanguage = computed(() => {
      refreshStreeTypes();
      return locale.value;
    });
    const lastLogin = computed(() => moment(appRef.value.profile.lastLogin[0]).format(appRef.value.formatDate) + ' ' + moment.utc(appRef.value.profile.lastLogin[0]).local().format('HH:mm:ss'));
    const noNif = computed(() => t("lang.inscriptions.noNif"));
    const anyFieldRequiredEmpty = computed(() => {
      const applicantDataValues = Object.values(applicantData.value);
      applicantDataValues.pop();
      return !(applicantDataValues.every((element) => element !== '') && representativeData.value.isValidForm());
    });
    const isValidNif = computed(() => InputService.isValidNie(applicantData.value.nif) || InputService.isValidNif(applicantData.value.nif));
    const orderedProcess = computed(() => filter.value.filteredData(locale.value));

    // shared properties
    const headerConfig = ref({});
    const availableLanguajes = ref([]);
    const imageClass = appRef.value.params.get('type') ? ProcessType.image[Number(appRef.value.params.get('type'))] : ProcessType.image[ProcessType.enum.CALL];
    const isLoading = ref(false);
    const isLoadingProcesses = ref(false);
    const cookie = ref(new Cookie());
    const showMandatoryCookies = ref(false);
    const showAnalysisCookies = ref(false);
    const showModalConfigurateCookies = ref(false);
    const showServiceStopModal = ref(false);
    const dontShowAgainStopServiceModalAgain = ref(false);
    const serviceStopLocalStorageKey = 'dontShowServiceStopModal';
    const serviceStopEndDate = moment('2024-05-15T17:00:00');
    const lastPublications = ref<Publication[]>([]);
    const isCompositionApi = true;

    // view properties
    const processTypeSelected = appRef.value.params.get('type') ? Number(appRef.value.params.get('type')) : 0;
    const applicantData: Ref<{ nif: string, name: string, firstSurname: string, secondSurname: string }> = ref({
      nif: '',
      name: '',
      firstSurname: '',
      secondSurname: ''
    });
    const representativeData = ref(new Representative({
      email: '',
      address: {
        country: Constants.country.SPAIN_CODE,
        province: '',
        foreignProvince: '',
        municipality: '',
        foreignMunicipality: '',
        streetType: '',
        streetName: '',
        number: '',
        portal: '',
        block: '',
        stair: '',
        floor: '',
        door: '',
        postCode: '',
        foreignPostCode: '',
      }
    }));
    const filter = ref(new Filter());
    const filters = Filters;
    const processList: Ref<Process[]> = ref([]);
    const isRepresentative = ref(false);
    const processSelectedId = ref('');
    const representativeForm = ref();
    const showToaster = ref(false);
    const modalVisible = ref(false);
    const inscriptionFile = ref('');
    const urlToInscription = ref('');
    const loadingStreetTypes = ref(false);
    const streetTypes = ref();
    const municipalities = ref();
    const countries = ref();
    const provinces = ref();
    const autonomousCommunityList = ref();

    // methods
    const getAllData = () => {
      getMasterData();
      getData();
      getAsideData();
      appRef.value.getProfile();
    };

    const getAsideData = () => {
      isLoading.value = true;
      getLastPublicationsList(Constants.publications.AMOUNT_OF_PUBLICATIONS).then((response: any) => {
        response.data.forEach(element => {
          lastPublications.value.push(new Publication(element));
        });
        getLastPublicationsListOrdered();
        isLoading.value = false;
      }).catch(() => {
        isLoading.value = false;
      });
    };

    const saveCookies = () => {
      cookie.value.savePersonalizateCookies();
      showModalConfigurateCookies.value = false;
    };

    const closeModalConfigurateCookies = () => {
      showModalConfigurateCookies.value = false;
      showMandatoryCookies.value = false;
      showAnalysisCookies.value = false;
    };

    const acceptAllCookies = () => {
      cookie.value.acceptAllCookies();
    };

    const declineAllCookies = () => {
      cookie.value.declineAllCookies();
    };

    const closeStopServiceModal = () => {
      showServiceStopModal.value = false;
    };

    const acceptStopServiceModal = () => {
      if (dontShowAgainStopServiceModalAgain) {
        const serviceStopData = { serviceStopNotificationReaded: dontShowAgainStopServiceModalAgain, expires: serviceStopEndDate };
        localStorage.setItem(serviceStopLocalStorageKey, JSON.stringify(serviceStopData));
      }
      showServiceStopModal.value = false;
    };

    const checkModalServiceStopLocalStorage = () => {
      const serviceStopItem = localStorage.getItem(serviceStopLocalStorageKey);
      if (!serviceStopItem) {
        return;
      }
      const serviceStopItemData = JSON.parse(serviceStopItem);
      if (moment() > moment(serviceStopItemData.expires)) {
        localStorage.removeItem(serviceStopLocalStorageKey);
      }
    };

    const getLastPublicationsListOrdered = () => {
      lastPublications.value = orderBy(lastPublications.value, ['date', lastPublication => deburr(lastPublication.title[locale.value]).toLowerCase()], ['desc', 'asc']);
    };

    const changeLocal = (language): void => {
      locale.value = language;
      appRef.value.changeLanguaje(language);
      getLastPublicationsListOrdered();
    };

    const getData = async () => {
      isLoadingProcesses.value = true;
      try {
        const [processesConfigResponse, processListResponse] = await Promise.all([
          getProcessesConfigurationGroup(),
          getProcessList(processTypeSelected)
        ]);
        
        // TO DO -> Tipar
        const processesConfigGroups = (processesConfigResponse as any).data;
        const processListData = (processListResponse as any).data;
    
        for (const element of processListData) {
          const process = new Process(element, processesConfigGroups);
          await gettingEntityById(element.entityOepId, process); // Ensure gettingEntityById is async
          processList.value.push(process);
        }
    
        processList.value = orderBy(
          processList.value,
          ['year', process => deburr(process.title[locale.value]).toLowerCase()],
          ['desc', 'asc']
        );
    
        filter.value.setData(processList.value);
        filter.value.setFilterElements(filters);
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingProcesses.value = false;
      }
    };

    const gettingEntityById = async (id: string, process: Process) => {
      try {
        const response = (await getEntityById(id)) as { data: { id: string, name: string } };
        const responseData = response as { data: { id: string, name: string } };
        const entity = new Entity(responseData.data.id, responseData.data.name);
        process.entity = entity;
      } catch (error) {
        console.log(error);
      }
    };

    const buildUrlToInscription = (id: string) => {
      return getMainFileById(id);
    };

    const openRepresent = () => {
      isRepresentative.value = true;
      applicantData.value = {
        nif: '',
        name: '',
        firstSurname: '',
        secondSurname: '',
      };
      representativeData.value = new Representative({
        email: '',
        address: {
          country: Constants.country.SPAIN_CODE,
          province: '',
          foreignProvince: '',
          municipality: '',
          foreignMunicipality: '',
          streetType: '',
          streetName: '',
          number: '',
          portal: '',
          block: '',
          stair: '',
          floor: '',
          door: '',
          postCode: '',
          foreignPostCode: '',
        }
      });
    };

    const refreshStreeTypes = () => {
      loadingStreetTypes.value = true;
      streetTypes.value = null;
      getMasterDataStreetTypes().then((responseDataStreetTypes: any) => {
        streetTypes.value = OrderSelect.prepareDataNotArray(responseDataStreetTypes.data);
        loadingStreetTypes.value = false;
        if (representativeForm.value) {
          nextTick(() => {
            InputService.checkValueAndUpdateInput(representativeForm.value);
          });
        }
      }).catch((error) => {
        console.error(error);
      });
    };

    const changedRepresentativeAddressCountry = () => {
      nextTick(() => {
        InputService.checkValueAndUpdateInput(representativeForm.value);
        representativeData.value.address.countryCode = Number(representativeData.value.address.country);
      });
    };

    const changedProvince = () => {
      (representativeData.value.address.municipality as any) = null;
      if (representativeData.value.address.province) {
        municipalities.value = [];
        nextTick(() => {
          getMasterDataMunicipalities(representativeData.value.address.province).then((municipalitiesData: any) => {
            municipalities.value = MunicipalitiesService.setMunicipalities(municipalitiesData.data, representativeData.value.address.municipality);
          }).catch((error) => {
            console.error(error);
          });
        });
      }
    };

    const getMasterData = () => {
      getMasterDataCountries().then((responseCountries: any) => countries.value = responseCountries.data).catch((error) => {
        console.error(error);
      });
      getMasterDataAutonomousCommunity().then((responseAutonomousCommunity: any) => autonomousCommunityList.value = OrderSelect.prepareDataNotArray(responseAutonomousCommunity.data)).catch((error) => {
        console.error(error);
      });
      getMasterDataStreetTypes().then((responseDataStreetTypes: any) => streetTypes.value = OrderSelect.prepareDataNotArray(responseDataStreetTypes.data)).catch((error) => {
        console.error(error);
      });
      getMasterDataProvinces().then((responseProvinces: any) => provinces.value = OrderSelect.prepareDataNotArray(responseProvinces.data)).catch((error) => {
        console.error(error);
      });
    };
    
    const onlyNumber = ($event, strict = false) => {
      const keyCode = $event.which ? $event.which : $event.keyCode;
      if (
        (keyCode < Constants.keyCodes.ZERO_KEY_CODE || keyCode > Constants.keyCodes.NINE_KEY_CODE) && strict ||
        (keyCode < Constants.keyCodes.ZERO_KEY_CODE || keyCode > Constants.keyCodes.NINE_KEY_CODE) &&
        keyCode !== Constants.keyCodes.DOT_KEY_CODE &&
        keyCode !== Constants.keyCodes.COMMA_KEY_CODE
      ) {
        $event.preventDefault();
      }
    };

    const onSelectFocus = ($event) => {
      select.onSelectFocus($event.target);
    };

    const onSelectBlur = ($event) => {
      select.onSelectBlur($event.target);
    };

    const onInputFocus = ($event: any) => {
      input.onInputFocus($event.target);
    };

    const onInputBlur = ($event: any) => {
      const elementNode = $event.target;
      input.onInputBlur(elementNode);
      if (!elementNode.validity.valid) {
        return;
      }
      InputService.validateFormRequired(elementNode.parentNode);
    };

    const validateForm = async () => {
      await nextTick();
      return InputService.validateFormRequired(representativeForm.value);
    };

    const goToInscription = () => {
      if (!validateForm()) {
        return;
      }
      const representativeToServer = representativeData.value.toServer();
      const representativeEncoded = encodeURIComponent(JSON.stringify(representativeToServer));

      location.href = 'inscription.html?id=' + processSelectedId.value + '&type=' + processTypeSelected + '&nif=' + applicantData.value.nif.toUpperCase() + '&firstSurname=' + applicantData.value.firstSurname + '&secondSurname=' + applicantData.value.secondSurname + '&name=' + applicantData.value.name + '&representative=' + representativeEncoded;
    };

    const goToDetail = (id: string) => {
      location.href = "processDetail.html?id=" + id + '&type=' + processTypeSelected;
    };

    const openModal = (id: string) => {
      processSelectedId.value = id;
      urlToInscription.value = `inscription.html?id=${id}`;
      buildUrlToInscription(id)
        .then(function (response: any) {
          if (response.data) {
            inscriptionFile.value = appRef.value.requestTo + response.data.url + "?origin=" + appRef.value.origin;
          }
          modalVisible.value = true;
        }).catch((error) => {
          console.log(error);
        });
    };

    const closeModal = () => {
      modalVisible.value = false;
      inscriptionFile.value = '';
      urlToInscription.value = '';
      processSelectedId.value = '';
      isRepresentative.value = false;
      applicantData.value = { nif: '', name: '', firstSurname: '', secondSurname: '' };
    };

    // lifecycle hooks
    onMounted(() => {
      loadCommonData({ availableLanguajes, getAllData, cookie: cookie.value, headerConfig, app: appRef.value, isCompositionApi }, appRef.value, locale);
      checkModalServiceStopLocalStorage();
      showServiceStopModal.value = appRef.value.isServiceStopActive && (!localStorage.getItem(serviceStopLocalStorageKey));
      if (appRef.value.params.get('certificateLogout') === 'true') {
        setTimeout(() => {
          showToaster.value = false;
        }, 8000);
      };
    });

    return {
      // dependencies
      t,
      app: appRef,
      ProcessType,
      Languages,
      constants: Constants,
      // shared view properties
      headerConfig,
      availableLanguajes,
      imageClass,
      isLoading,
      cookie,
      showMandatoryCookies,
      showAnalysisCookies,
      showModalConfigurateCookies,
      showServiceStopModal,
      dontShowAgainStopServiceModalAgain,
      serviceStopLocalStorageKey,
      serviceStopEndDate,
      lastPublications,
      isCompositionApi,
      representativeData,
      loadingStreetTypes,
      streetTypes,
      municipalities,
      countries,
      provinces,
      autonomousCommunityList,
      // computed properties
      pageTitle,
      currentLanguage,
      lastLogin,
      noNif,
      anyFieldRequiredEmpty,
      isValidNif,
      orderedProcess,
      // methods
      getAllData,
      getData,
      getAsideData,
      getLastPublicationsListOrdered,
      saveCookies,
      closeModalConfigurateCookies,
      acceptAllCookies,
      declineAllCookies,
      changeLocal,
      acceptStopServiceModal,
      closeStopServiceModal,
      goToDetail,
      openModal,
      openRepresent,
      goToInscription,
      closeModal,
      onInputFocus,
      onInputBlur,
      refreshStreeTypes,
      changedRepresentativeAddressCountry,
      changedProvince,
      onlyNumber,
      onSelectFocus,
      onSelectBlur,
      // view properties
      modalVisible,
      inscriptionFile,
      urlToInscription,
      ProcessStatus,
      ProcessPhases,
      OppositionType,
      FilterTypes,
      processSelectedId,
      isRepresentative,
      applicantData,
      filter,
      filters,
      processTypeSelected,
      isLoadingProcesses,
      showToaster,
      processList,
      spainCode: Constants.country.SPAIN_CODE,
      fiveZeros: Constants.postalCode.FIVE_ZEROS,
      // refs
      representativeForm
    };
  },
});

const vueApp = createApp(IndexComponent);
vueApp.use(i18n);
vueApp.config.compilerOptions.whitespace = 'preserve';
vueApp.mount(Constants.APP);
