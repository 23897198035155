import { Address } from "./entities/inscriptionStructure/Address";
import { Constants } from "./enum/Constants";

export class Representative {
    private _name: string;
    private _firstSurname: string;
    private _secondSurname: string;
    private _nif: string;
    private _fullNameToView: string;
    private _address: Address | null;
    private _email: string;

    constructor(data: any) {
        this._name = data.name;
        this._firstSurname = data.firstSurname;
        this._secondSurname = data.secondSurname;
        this._nif = data.nif;
        this._fullNameToView = this.setFullName();
        this._address = data.address ? new Address(data.address) : null;
        this._email = data.email;
    }

    public toServerInscription() {
        return {
            name: this._name,
            firstSurname: this._firstSurname,
            secondSurname: this._secondSurname,
            nif: this._nif,
            address: this._address?.toServerInscription(),
            email: this._email
        }
    }

    public toServer() {
        return {
            name: this._name,
            firstSurname: this._firstSurname,
            secondSurname: this._secondSurname,
            nif: this._nif,
            address: this._address?.toServerInscription(),
            contactData: { email: this._email }
        }
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get firstSurname() {
        return this._firstSurname;
    }

    public set firstSurname(firstSurname: string) {
        this._firstSurname = firstSurname;
    }

    public get secondSurname() {
        return this._secondSurname;
    }

    public set secondSurname(secondSurname: string) {
        this._secondSurname = secondSurname;
    }

    public get nif() {
        return this._nif;
    }

    public set nif(nif: string) {
        this._nif = nif;
    }

    public get address() {
        return this._address ? this._address : {} as Address;
    }

    public set address(address: Address) {
        this._address = address;
    }

    public get email() {
        return this._email;
    }

    public set email(email: string) {
        this._email = email;
    }

    public get fullNameToView() {
        return this._fullNameToView;
    }

    public setFullName() {
        const surnames = this.firstSurname + ' ' + this.secondSurname;
        return surnames.trim() + ', ' + this.name;
    }

    public isValidForm() {
        const empty = '';
        const standardValidation = this.address.streetName !== empty && this.email !== empty;
        const locallyValidation = this.address.province !== null && this.address.province.toString() !== empty && this.address.municipality !== null && this.address.municipality.toString() !== empty && this.address.postCode !== null && this.address.postCode.toString() !== empty;
        const foreignValidation = this.address.foreignProvince !== empty && this.address.foreignProvince !== null && this.address.foreignMunicipality !== empty && this.address.foreignMunicipality !== null && this.address.foreignPostCode !== empty && this.address.foreignPostCode !== null;

        return this.address.country === Constants.country.SPAIN_CODE ? (locallyValidation && standardValidation) : (foreignValidation && standardValidation);
    }

}