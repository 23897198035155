import IEnum from '../interfaces/IEnum';

export const OppositionType: IEnum = {
    translations: {
        0: 'lang.shared.contest',
        1: 'lang.shared.oppositionContest',
        2: 'lang.shared.opposition'
    },
    enum: {
        TENDER: 0,
        OPPOSITION: 1,
        NOMINATION: 2
    }
};

