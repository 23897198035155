import { FilterElement } from '../../domain/entities/filter/FilterElement';
import { InputFilterElement } from '../../domain/entities/filter/InputFilterElement';
import { FilterTypes } from '../../domain/enum/FilterTypes';

export class FilterFactory {

    public createFilterElement(data: any): FilterElement {
        switch (data.type) {
            case FilterTypes.enum.INPUT:
                return new InputFilterElement(data);
            default:
                return {} as FilterElement;
        }
    }
}
