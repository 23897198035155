export class Turns {
    private _free: boolean;
    private _internal: boolean;
    private _internalDisability: boolean;
    private _freeDisability: boolean;
    private _freeDesignation: boolean;
    private _freeDesignationDisability: boolean;

    constructor(data: any) {
        this._free = data.free;
        this._internal = data.internal;
        this._internalDisability = data.internalDisability;
        this._freeDisability = data.freeDisability;
        this._freeDesignation = data.freeDesignation;
        this._freeDesignationDisability = data.freeDesignationDisability;
    }

    public get free(): boolean {
        return this._free;
    }

    public get internal(): boolean {
        return this._internal;
    }
    
    public get internalDisability(): boolean {
        return this._internalDisability;
    }

    public get freeDisability(): boolean {
        return this._freeDisability;
    }

    public get freeDesignation(): boolean {
        return this._freeDesignation;
    }

    public get freeDesignationDisability(): boolean {
        return this._freeDesignationDisability;
    }

}