export abstract class FilterElement {
    private _type: number;
    private _label: string;
    private _param: string;
    private _isDropped: boolean;

    constructor(data: any) {
        this._type = data.type;
        this._label = data.label;
        this._param = data.param;
        this._isDropped = data.isDropped;
    }

    public get type() {
        return this._type;
    }
    public get label() {
        return this._label;
    }
    public get param() {
        return this._param;
    }
    public get isDropped() {
        return this._isDropped;
    }
    public set isDropped(isDropped: boolean) {
        this._isDropped = isDropped;
    }
    public abstract get hasFilterValue(): boolean;

    public abstract reset();
    public abstract showElement(element: any, currentLanguage?: string): boolean;
}
